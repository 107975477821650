
:root {
    --ck-color-base-foreground: #fafafa;
    --ck-color-base-background: #000;
    --ck-color-base-border: #c4c4c4;
    --ck-color-base-action: #61b045;
    --ck-color-base-focus: #6cb5f9;
    --ck-color-base-text: #333;
    --ck-color-base-active: #198cf0;
    --ck-color-base-active-focus: #0e7fe1;
    --ck-color-base-error: #db3700;
    --ck-color-focus-border-coordinates: 208,79%,51%;
    --ck-color-focus-border: hsl(var(--ck-color-focus-border-coordinates));
    --ck-color-focus-outer-shadow: #bcdefb;
    --ck-color-focus-disabled-shadow: rgba(119,186,248,0.3);
    --ck-color-focus-error-shadow: rgba(255,64,31,0.3);
    --ck-color-text: var(--ck-color-base-text);
    --ck-color-shadow-drop: rgba(0,0,0,0.15);
    --ck-color-shadow-drop-active: rgba(0,0,0,0.2);
    --ck-color-shadow-inner: rgba(0,0,0,0.1);
    --ck-color-button-default-background: transparent;
    --ck-color-button-default-hover-background: #e6e6e6;
    --ck-color-button-default-active-background: #d9d9d9;
    --ck-color-button-default-active-shadow: #bfbfbf;
    --ck-color-button-default-disabled-background: transparent;
    --ck-color-button-on-background: #dedede;
    --ck-color-button-on-hover-background: #c4c4c4;
    --ck-color-button-on-active-background: #bababa;
    --ck-color-button-on-active-shadow: #a1a1a1;
    --ck-color-button-on-disabled-background: #dedede;
    --ck-color-button-action-background: var(--ck-color-base-action);
    --ck-color-button-action-hover-background: #579e3d;
    --ck-color-button-action-active-background: #53973b;
    --ck-color-button-action-active-shadow: #498433;
    --ck-color-button-action-disabled-background: #7ec365;
    --ck-color-button-action-text: var(--ck-color-base-background);
    --ck-color-button-save: #008a00;
    --ck-color-button-cancel: #db3700;
    --ck-color-switch-button-off-background: #b0b0b0;
    --ck-color-switch-button-off-hover-background: #a3a3a3;
    --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
    --ck-color-switch-button-on-hover-background: #579e3d;
    --ck-color-switch-button-inner-background: var(--ck-color-base-background);
    --ck-color-switch-button-inner-shadow: rgba(0,0,0,0.1);
    --ck-color-dropdown-panel-background: var(--ck-color-base-background);
    --ck-color-dropdown-panel-border: var(--ck-color-base-border);
    --ck-color-input-background: var(--ck-color-base-background);
    --ck-color-input-border: #c7c7c7;
    --ck-color-input-error-border: var(--ck-color-base-error);
    --ck-color-input-text: var(--ck-color-base-text);
    --ck-color-input-disabled-background: #f2f2f2;
    --ck-color-input-disabled-border: #c7c7c7;
    --ck-color-input-disabled-text: #757575;
    --ck-color-list-background: var(--ck-color-base-background);
    --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
    --ck-color-list-button-on-background: var(--ck-color-base-active);
    --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
    --ck-color-list-button-on-text: var(--ck-color-base-background);
    --ck-color-panel-background: var(--ck-color-base-background);
    --ck-color-panel-border: var(--ck-color-base-border);
    --ck-color-toolbar-background: var(--ck-color-base-foreground);
    --ck-color-toolbar-border: var(--ck-color-base-border);
    --ck-color-tooltip-background: var(--ck-color-base-text);
    --ck-color-tooltip-text: var(--ck-color-base-background);
    --ck-color-engine-placeholder-text: #707070;
    --ck-color-upload-bar-background: #6cb5f9;
    --ck-color-link-default: #0000f0;
    --ck-color-link-selected-background: rgba(31,177,255,0.1);
    --ck-color-link-fake-selection: rgba(31,177,255,0.3);
    --ck-disabled-opacity: .5;
    --ck-focus-outer-shadow-geometry: 0 0 0 3px;
    --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-outer-shadow);
    --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-disabled-shadow);
    --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-error-shadow);
    --ck-focus-ring: 1px solid var(--ck-color-focus-border);
    --ck-font-size-base: 13px;
    --ck-line-height-base: 1.84615;
    --ck-font-face: Helvetica,Arial,Tahoma,Verdana,Sans-Serif;
    --ck-font-size-tiny: 0.7em;
    --ck-font-size-small: 0.75em;
    --ck-font-size-normal: 1em;
    --ck-font-size-big: 1.4em;
    --ck-font-size-large: 1.8em;
    --ck-ui-component-min-height: 2.3em;
}

// .form-control {
//     background-color: #354650!important;
//     border: 2px solid #415663;
//     border-radius: 4px;
//     font-size: 14px;
//     max-width: 100%;
//     color: #ffffff;
//     -webkit-box-shadow: none;
//     box-shadow: none;
// }
.form-control:focus {
    // color: white;
    box-shadow: none;
}

.card-box,
.card {
    padding: 20px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
    margin-bottom: 20px;

}
.card {
    padding: 20px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
    margin-bottom: 20px;
    border: 1px solid $standard-border-color;
}
.card-box {
    border-radius: 0px;
    background-color: $medium-gray;
}

.btn-warning {
    color: white !important;
}
.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.pagination>li>a, .pagination>li>span {
    color: #98a6ad !important;;
    background-color: #354650 !important;
    border-color: #475e6c !important;;
}
.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
a {
    color: white;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #375a7f !important;
    border-color: transparent;
}

.form-error {
    margin: 8px 0;
    color: #ff5506;
}

body {
    font-family: "Poppins", sans-serif!important;
    margin: 0;
    overflow-x: hidden;
    color: white;
    font-size: 16px;
    display: grid;
    grid-template-rows: 1fr;
}

.grid-holder {
    background: #262b2e;
    padding: 15px;
}
table tr:first-child th:first-child {
    border-top-left-radius: 10px;
}
table tr:first-child th:first-child.no-rounding {
    border-radius: 0;
}
table tr:first-child th:last-child {
    border-top-right-radius: 10px;
}
table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
table.datagrid {
    background: #222;
    border: 1px solid gray;
    border-radius: 5px;
}

table.datagrid tr {
    border-top: 1px solid gray;
}
table.datagrid tr td, table.datagrid tr th{
    padding: 0.5rem;
    text-align: left;
}

table.datagrid tbody tr:nth-of-type(odd) {
    background-color: #303030;
}

.error {
    @apply text-red-500 mt-1.5;
}

input.finso-form-input, select.finso-form-input, textarea.finso-form-input, trix-editor {
    @apply outline-none focus:outline-none px-4 sm:text-base;
    background-color: #303030;
    border-radius: 10px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #B5B5BE;
    font-size: 15px;

        @media (min-width: 640px) {
            font-size: 16px;
        }

    &.dark {
        @apply finso-bg-color;
        color: #FFFFFF;

    }
    &.high {
        @apply py-2;
    }

    &::placeholder {
        color: #B5B5BE;
    }
}
input.finso-form-input {
    width: 240px;
    @media (min-width: 1280px) {
        width: 150px;
    }
    &.full {
        width: 100%;
    }
}
input.table-form.finso-form-input,
select.table-form.finso-form-input {
    width: auto;
    background-color: $finso-black;

    color: #FFFFFF;

    &::placeholder {
        color: $white-faded;
    }

}
select.finso-form-input,
select.line-label-select {
    width: 240px;
    @media (min-width: 1280px) {
        width: 200px;
    }
}

.finso-label {
    @apply uppercase tracking-wide text-xs mb-2 leading-7;
}

.toggle-checkbox:checked {
    @apply right-0 border-green-400;
    border-color: #2D9AFF;
}
.toggle-checkbox:checked + .toggle-label {
    @apply bg-green-400;
    background-color: #2D9AFF;
}

.icon-button {

    display: inline-block;
    width: 35px;
    height: 32px;
    text-align: center;
    background-color: $medium-gray;
    padding: 8px;
    border-radius: 4px;

    & a {
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
    }

    // &:not(:first-child) {
    //     @media (min-width: 768px) {
    //         margin-left: 4px;
    //     }
    // }

}

// @media (min-width: 1536px) {
    .container {
        max-width: 100%;
    }
// }
[x-cloak] {
    display: none !important;
}


trix-toolbar .trix-button--icon::before {
    background-color: white;
}
trix-toolbar .trix-dialog__link-fields .trix-input {
    color: black;
}

trix-editor .attachment__caption-editor {
    background-color: black;
}
trix-editor blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
}
trix-editor pre {
    display: block;
    font-family: "Courier New", serif;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
}

trix-editor ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}
trix-editor ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

/* Tab content - closed */
.email-history-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
}
/* :checked - resize to full height */
.email-history input:checked ~ .email-history-content {
    max-height: 100vh;
}
/* Label formatting when open */
.email-history input:checked + label{
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    background-color: rgb(31,41,55);
    color: white;
}
/* Icon */
.email-history label::after {
    float:right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
}
/* Icon formatting - closed */
.email-history input[type=checkbox] + label::after {
    content: "+";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
}
.email-history input[type=radio] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
}
/* Icon formatting - open */
.email-history input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
    background-color: #6574cd; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
}
.email-history input[type=radio]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #6574cd; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
}

// toaster default z-index is 50 but that will be below the modals
[id^="tailwind-toast"] {
    z-index: 5000;
}
// tawilwind-toast-two
[id^="tawilwind-toast"] {
    z-index: 5000;
}

.finso-bg-color {
    background-color: $finso-black;
}
.standard-border {
    border: 1px solid $standard-border-color
}
.finso-main-menu-link-hover {
    border-bottom: 1px solid transparent;

    &:hover i,
    &:focus i {
        color: #0062FF;
    }
    &:hover,
    &:focus {
        border-bottom: 1px solid #0062FF;
    }
}
.active-main-menu {
    border-bottom: 1px solid #0062FF;

    & i {
        color: #0062FF;
    }

}
.finso-menu-link-hover {
    &:hover,
    &:focus {
        color: white;
        background-color: $standard-border-color;
    }
}

.finso-table-style {
    &.with-background {
        background-color: $tab-background-color;
    }

    & .tabs-polygon-background {

        & .finso-tab {
            // background-color: $tab-background-color;
            padding: 8px 20px;
            outline: none;

            // @media (min-width: 640px) {
            //     margin-right: -5px;
            // }

            &.selected {
                background-color: $dark-gray;
            }


        }
        &.headline-create-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 160px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 160px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);

                                }
                            }
                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);

                        }
                    }


                }
            }
        }
        &.headline-edit-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 625px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 615px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 615px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 160px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 150px 1%, 100% 100%, 0% 100%);

                                }
                            }
                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 150px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.widget-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 420px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 410px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 410px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 210px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.entity-plan-tabs {
            @apply flex-col;

            @media (min-width: 1024px) {
                @apply flex-row;

                width: 900px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 890px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 890px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 150px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 140px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 140px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 140px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 140px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 140px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 140px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 140px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 140px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.entity-bundle-tabs {
            @apply flex-col;

            @media (min-width: 1280px) {
                @apply flex-row;

                width: 1190px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 1180px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 1180px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 170px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 160px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 160px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 160px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 160px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 160px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 160px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 160px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 160px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.tcvideo-widget-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 630px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 620px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 620px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 210px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 200px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 200px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.vidlib-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 360px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 350px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 350px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 180px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 170px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 170px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 170px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 170px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 170px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 170px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 170px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 170px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }

        &.twitter-tabs {
            @apply flex-col;

            @media (min-width: 640px) {
                @apply flex-row;

                width: 425px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 415px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 415px 1%, 100% 100%, 0% 100%);
                }

                & .finso-tab {
                    width: 110px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 90% 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 90% 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 90% 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 90% 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 90% 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 90% 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 90% 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 90% 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }
        }
        &.prod-2-tabs {
            @apply flex-col;

            @media (min-width: 1024px) {
                @apply flex-row;

                width: 290px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 280px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 280px 1%, 100% 100%, 0% 100%);
                }
                & .finso-tab {
                    width: 145px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 135px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 135px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 135px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 135px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 135px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 135px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 135px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 135px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }

            // & .finso-tab {
            //     width: 185px;
            // }
        }
        &.prod-5-tabs {
            @apply flex-col;

            @media (min-width: 1024px) {
                @apply flex-row;

                width: 700px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 690px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 690px 1%, 100% 100%, 0% 100%);
                }
                & .finso-tab {
                    width: 140px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }

            // & .finso-tab {
            //     width: 185px;
            // }
        }
        &.prod-8-tabs {
            @apply flex-col;

            @media (min-width: 1280px) {
                @apply flex-row;

                width: 1120px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 1110px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 1110px 1%, 100% 100%, 0% 100%);
                }
                & .finso-tab {
                    width: 140px;

                    &:first-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);

                            &.selected {
                                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                    -webkit-clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 130px 1%, 100% 100%, 0% 100%);

                                }
                            }

                        }
                    }
                    &:last-child {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);

                        }
                    }

                    &.selected {
                        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                            -webkit-clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);
                            clip-path: polygon(10px 0, 130px 1%, 100% 100%, 0% 100%);

                        }
                    }

                }
            }

            // & .finso-tab {
            //     width: 185px;
            // }
        }
        &.users-row-1 {
            @apply flex-col;

            @media (min-width: 1024px) {
                @apply flex-row;

                width: 760px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 750px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 750px 1%, 100% 100%, 0% 100%);
                }

            }
        }
        &.users-row-2,
        &.users-row-3 {

            @apply flex-col;

            @media (min-width: 1024px) {
                @apply flex-row;

                width: 950px;
                @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                    -webkit-clip-path: polygon(0 0, 940px 1%, 100% 100%, 0% 100%);
                    clip-path: polygon(0 0, 940px 1%, 100% 100%, 0% 100%);
                }

            }


            // & .finso-tab {
            //     width: 185px;
            // }
        }
        &.users-row-1,
        &.users-row-2,
        &.users-row-3 {

            & .finso-tab {
                width: 190px;

                &:first-child {
                    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                        -webkit-clip-path: polygon(0 0, 180px 1%, 100% 100%, 0% 100%);
                        clip-path: polygon(0 0, 180px 1%, 100% 100%, 0% 100%);

                        &.selected {
                            @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                                -webkit-clip-path: polygon(0 0, 180px 1%, 100% 100%, 0% 100%);
                                clip-path: polygon(0 0, 180px 1%, 100% 100%, 0% 100%);

                            }
                        }

                    }
                }
                &:last-child {
                    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                        -webkit-clip-path: polygon(10px 0, 180px 1%, 100% 100%, 0% 100%);
                        clip-path: polygon(10px 0, 180px 1%, 100% 100%, 0% 100%);

                    }
                }

                &.selected {
                    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
                        -webkit-clip-path: polygon(10px 0, 180px 1%, 100% 100%, 0% 100%);
                        clip-path: polygon(10px 0, 180px 1%, 100% 100%, 0% 100%);

                    }
                }

            }
        }

    }

    .tab-background {
        background-color: $tab-background-color;
    }

    & .finso-col-name {
        color: #B5B5BE;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 21px;
        letter-spacing: 0.1px;

        & i {
            color: #FFFFFF;
        }
    }

}

.standard-border-color-background {
    background-color: $standard-border-color;
}

.finso-button {
    @apply cursor-pointer items-center text-white px-6 py-2;
    width: fit-content;
    border-radius: 10px;
    height: 40px;
    max-height: 40px;

    &.green {
        background-color: #3B9C83;
    }
    &.gray {
        background-color: $standard-border-color;
    }
    &.red {
        background-color: #FC5A5A;
    }
    &.blue {
        background-color: #0062FF;
    }
    &.yellow {
        background-color: #EAC551;
    }
    &:hover {
        color: white;
    }
}

.table-footer {

    & p {
        color: #B5B5BE;
    }

    & nav > div:nth-child(2) > div:nth-child(2) > span span,
    & nav > div:nth-child(2) > div:nth-child(2) > span button {
        background-color: transparent;
        border: none;
        color: #B5B5BE;
    }

    & nav > div:nth-child(2) > div:nth-child(2) span[aria-current="page"] span {
        background-color: $medium-gray;
    }

    & nav > div:nth-child(2) > div:nth-child(2) > span > span {
        &:first-child button,
        &:last-child button {
            background-color: $medium-gray;
        }
    }

    & nav > div:first-child > span > span,
    & nav > div:first-child > span > button {
        border: none;
        color: #B5B5BE;
    }
    & nav > div:first-child > span > span {
        background-color: transparent;
    }
    & nav > div:first-child > span > button {
        background-color: $medium-gray;
    }
}

.core-bg-lightred {
    background-color: #FC5A5A;
}
.core-bg-yellow {
    background-color: #EAC551;
}
.core-bg-green {
    background-color: #3B9C83;
}
.dropdown-menu-style {
    @apply finso-bg-color rounded-md standard-border;
    border-radius: 8px;
    padding: 4px;
}
.card.welcome-card {
    @apply finso-bg-color standard-border;
    border-radius: 10px;

}
.line-label-select,
.line-label-datepicker {

    // select {
    //     height:40px;
    //     position:relative;
    //     display:block;
    //     appearance: none;
    //     -webkit-appearance: none;
    //     -moz-appearance: none;
    //     -o-appearance: none;
    //     -ms-appearance: none;
    //     -webkit-box-shadow: none;
    // }
    // label {
    //     position:absolute;
    //     z-index:1;
    //     height:40px;
    //     line-height:40px;
    //     pointer-events:none;
    //     text-indent:10px;
    // }

    & select,
    & input {
        display: inline-block;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-box-shadow: none;
        margin-left: -5px;
        padding-left: 0.5rem;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #FFFFFF;
        font-size: 15px;

        @media (min-width: 640px) {
            font-size: 16px;
        }
    }
    & select,
    & input.date-to {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    & input.date-from {
        border-radius: 0;
        width: 85px;
    }

    & input.date-to {
        width: 85px;
    }

    & label {
        background-color: #303030;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: inline-block;
        line-height: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 1rem;
        pointer-events: none;
        color: #B5B5BE;
        font-size: 15px;

        @media (min-width: 640px) {
            font-size: 16px;
        }


    }
}

.line-label-datepicker {
    display: flex;
    & label {
        background-color: #303030;
    }

    & input {
        margin: 0;
    }


}
div.flatpickr-calendar {
    background-color: #303030;

    &.arrowTop:before {
        border-bottom-color: #303030;
    }

    & .flatpickr-months {
        background-color: #303030;

        & .flatpickr-current-month {
            & .flatpickr-monthDropdown-months {
                background-color: #303030;
            }
            & .flatpickr-monthDropdown-month {
                background-color: #303030;
            }
        }

        & .flatpickr-month {
            background-color: #303030;
        }
        & .flatpickr-weekdays {
            background-color: #303030;
        }

    }
    & span.flatpickr-weekday {
        background-color: #303030;
    }
}

.add-ml-on-lg {
    & > div {
        @apply mb-4;

        &:not(:first-child) {

            @media(min-width: 1280px) {
                margin-left: 8px;
                margin-bottom: 0;
            }
        }

    }
}
.add-m-on-lg-pp {
    margin-right: 8px;
    @apply mt-4;

    @media(min-width: 1280px) {
        @apply mt-0;
    }
}
.add-m-on-lg-include {
    & > div {
        @apply mt-4;

            @media(min-width: 1280px) {
                @apply mt-0;

                &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
.vidlib-frame {

    & .vidlib-draggable {
        @apply p-4 flex items-center relative;

        background-color: $dark-gray;
        border-radius: 10px;

        &:nth-child(even) {
            background-color: $medium-gray;
        }

    }

}



.footer {
    @apply finso-bg-color standard-border;
    border-right: none;
    border-bottom: none;
    border-left: none;
    text-align: left !important;
    padding: 25px 30px 25px;
    right: 0px;
    left: 250px;
}
.under-app-menu-space {
    @media (min-width: 768px) {
        margin-top: 70px;
    }

    @media (min-width: 1024px) {
        margin-top: 60px;
    }
}

.form-tab-content {
    padding: 15px 10px;

    @media (min-width: 1024px) {
        padding: 15px 30px;
    }
}

.one-col-form {
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 16px;

    &.no-margin {
        margin: 0;
    }

    & label,
    & > div:first-child{
        color: $white-faded;
        margin-bottom: 5px;
        @media (min-width: 1024px) {
            margin-bottom: 0;
        }
    }
    // & input.finso-form-input,
    // & select.finso-form-input {
    //     height: 40px;
    // }

    & input.finso-form-input,
    & select.finso-form-input,
    & trix-editor,
    & textarea.finso-form-input {
        width: 100%;
        border: none;
        background-color: $finso-black;
    }
    & .absolute-input-icon {

        @media (min-width: 1024px) {
            left: 50%;
        }
    }

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 250px 1fr;
        align-items: center;
        column-gap: 30px;

        &.items-start {
            align-items: start;
        }
        & input.finso-form-input,
        & select.finso-form-input {
            width: 50%;
        }
    }

}
.two-col-container {
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;

        & .one-col-form {

            // grid-template-columns: 180px 1fr;

            & input.finso-form-input,
            & select.finso-form-input {
                width: 100%;
            }
        }
    }
}
button.button-disabled {
    cursor: not-allowed;
    background-color: $tab-background-color;
    color: $white-faded;
}

.delete-icon-placing {
    @apply top-3;
    right: 10px;
    @media (min-width: 1024px) {
        right: unset;
        left: 47.5%;
    }
}

.control-icon-button {
    @apply cursor-pointer rounded-lg;
    background-color: $finso-black;
    width: 56px;
    height: 56px;
    text-align: center;
}
.media-container {
    border-radius: 10px!important;
    border: none!important;
}
.media-box {
    background-color: $finso-black!important;
    border: none!important;
    margin: 0!important;
}
.white-link, .yellow-link {
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: #0062FF;
    }
}
.white-link {
    color: #FFFFFF;
    text-decoration: underline;
}
.yellow-link {
    color: #EAC551;
    text-decoration: none;
}
.finso-image-gallery {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
    grid-auto-rows: 120px;

    & img {
        width: 100%;
        height: 120px;
        object-fit: cover;
    }

}
.radiobox-label {
    @apply color-white-faded uppercase ml-2;
    font-size: 14px;
}
.no-flex-vimeo .vp-center {
    display: block!important;
}
.background-finso-black {
    background-color: $finso-black;
}
.background-dark-gray {
    background-color: $dark-gray;
}
.background-lightred {
    background-color: #FC5A5A;
}
.text-16 {
    font-size: 16px;
}
.color-green {
    color: #3B9C83;
}
.color-lightred {
    color: #FC5A5A;
}
.color-white-faded {
    color: $white-faded;
}
.color-yellow {
    color: #EAC551;
}
