// Fonts

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@import "~tailwindcss/tailwind.css";

@import "variables";
@import "bootstrapify";
@import "finso";
@import '~@sweetalert2/theme-dark/dark.scss';

.filepond--root {
    background: grey;
    color: white;
    cursor: pointer;
}

table tbody tr {
    background: #2A2A2A;
}
table tbody tr:nth-child(even),
.table-header-section {
    background: #353535;
}
// table tbody tr:hover{
//     background: #101010;
//     opacity: 1;
// }

trix-editor ul{
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}
trix-editor li {
    display: list-item;
}

.drag-and-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
    }
    max-width: 760px;
    margin: 0 auto;

    &__container {
        position: relative;
        overflow: hidden;
        width: 50%;
        border-radius: 10px;
    }

    &__divider {
        padding: 10px;
        font-size: 20px;
    }

    &__title {
        padding: 15px 20px;
        font-size: 20px;
        background: #353535;
        margin: 0;
        font-size: 16px;
        text-transform: uppercase;
        color: $white-faded;
        text-align: center;
    }

    &__items {
        position: relative;
        overflow: hidden;
        height: 100%;
        min-height: 150px;
        margin: 0 -1px;

        &--adding {
            background-color: #9AE6B4;
        }

        &--removing {
            background-color: #956c36;
        }
    }

    &__item {
        padding: 15px 20px;
        line-height: 1;
        cursor: move;
        background-color: #2A2A2A;

        &:nth-child(even) {
            background-color: #353535;
        }

        &:hover {
            background-color: #342f2f;
        }

        &--dragging {
            color: #ccc !important;
            background-color: #000 !important;
        }
    }
}
.tree {
    ol {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
    li {
        display: list-item;
    }

}
.vidlib {
    ol {
        display: block;
        list-style-type: none;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
    li {
        display: list-item;
    }

}
.analyse-users {
    color: yellow;
    cursor: pointer;
    user-select: none;
}
