// Body
// $body-bg: #f8fafc;

// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

$tab-background-color: rgba(#2A2A2A, 0.6);
$standard-border-color: #383A42;
$finso-black: #1C1C1C;
$white-faded: rgba(#FFFFFF, 0.6);
$dark-gray: #2A2A2A;
$medium-gray: #353535;