.h1, h1 {
    @apply text-4xl
}

.h2, h2 {
    @apply text-3xl
}

.h3, h3 {
    @apply text-2xl
}

.h4, h4 {
    @apply text-xl
}

.h5, h5 {
    @apply text-lg
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    @apply mb-2 font-medium leading-tight
}

a {
    @apply text-blue-500;
}

a:hover {
    @apply text-blue-800;
}

.text-muted {
    @apply text-gray-600;
}

.bg-dark {
    @apply bg-gray-900;
}

.bg-light {
    @apply bg-gray-200;
}

.close {
    @apply float-right text-2xl font-bold leading-none text-black opacity-50
}

/*
* ============================
* Alerts
*=============================
*/

.alert {
    @apply relative px-5 py-3 mb-4 rounded border border-transparent;
}

.alert-primary {
    @apply text-blue-800 bg-blue-200 border-blue-300;
}

.alert-secondary {
    @apply text-gray-800 bg-gray-300 border-gray-400;
}

.alert-success {
    @apply text-green-800 bg-green-200 border-green-300;
}

.alert-danger {
    @apply text-red-800 bg-red-200 border-red-300;
}

.alert-warning {
    @apply text-yellow-800 bg-yellow-200 border-yellow-300;
}

.alert-info {
    @apply text-indigo-800 bg-indigo-200 border-indigo-300;
}

/*
* ============================
* Buttons
*=============================
*/

.btn {
    @apply inline-block font-normal text-center px-3 py-2 leading-normal text-base rounded cursor-pointer;
}

.btn-primary {
    @apply text-white bg-blue-600;
}

.btn-primary:hover {
    @apply text-white bg-blue-700
}

.btn-secondary {
    @apply text-white bg-gray-600;
}

.btn-secondary:hover {
    @apply text-white bg-gray-700
}

.btn-success {
    @apply text-white bg-green-600;
}

.btn-success:hover {
    @apply text-white bg-green-700
}

.btn-danger {
    @apply text-white bg-red-600;
}

.btn-danger:hover {
    @apply text-white bg-red-700
}

.btn-warning {
    @apply text-black bg-yellow-500;
}

.btn-warning:hover {
    @apply text-black bg-yellow-600
}

.btn-info {
    @apply text-white bg-gray-500;
}
.btn-info:hover {
    @apply text-white bg-gray-600
}

.btn-light {
    @apply text-black bg-gray-200;
}

.btn-light:hover {
    @apply text-black bg-gray-300
}

.btn-dark {
    @apply text-white bg-gray-900;
}

.btn-dark:hover {
    @apply text-white bg-black
}

.btn-link {
    @apply text-blue-500;
}

.btn-link:hover {
    @apply text-blue-800;
}


/*
* ============================
* Cards
*=============================
*/

.card {
    @apply flex flex-col relative rounded-lg border p-5 shadow-2xl;
}

.card-body {
    @apply flex-auto p-5;
}

.card-img-top {
    @apply w-full rounded-t;
}

.card-link + .card-link {
    @apply ml-5;
}

.card-text {
    @apply my-0 mb-4;
}

.card-title {
    @apply mb-3 text-xl;
}

/*
!*
* ============================
* Dropdowns
*=============================
*!

.dropdown {
    @apply relative;
}

.dropdown-divider {
    @apply h-0 my-2 border-t border-gray-300;
}

.dropdown-menu {
    min-width: 10rem;
    @apply hidden z-20 float-left py-2 mt-1 text-base text-gray-900 list-none text-left border border-gray-300 bg-white rounded shadow;
}

.dropdown-menu.show, .dropdown.open .dropdown-menu {
    @apply block absolute top-0 left-0 mt-12;
}

.dropdown-item {
    @apply block w-full py-1 px-6 font-normal text-gray-900 bg-transparent border-0;
}
.dropdown-item:hover {
    @apply text-gray-900 bg-gray-100;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

!*
* ============================
* Forms
*=============================
*!

form {
    @apply block;
}

label {
    @apply inline-block mb-2;
}

.form-check {
    @apply relative block pl-5;
}

.form-group {
    @apply mb-4;
}

.form-check-input {
    @apply absolute mt-1 -ml-5;
}

.form-check-label {
    @apply mb-0;
}

.form-control {
    @apply block w-full py-2 px-3 text-base font-normal leading-normal text-gray-700 bg-white border border-gray-400 rounded;
}
*/

.form-inline {
    @apply flex flex-wrap flex-row items-center
}

.form-inline .form-control {
    @apply inline-block w-auto align-middle
}

.form-text {
    @apply block mt-1;
}


/*
* ============================
* List Groups
*=============================
*/

.list-group {
    @apply flex flex-col p-0 mb-0;
}

.list-group-item {
    margin-bottom: -1px;
    @apply relative block py-3 px-5 bg-white border border-gray-300;
}

.list-group-item:first-child {
    @apply rounded-t;
}

.list-group-item:last-child {
    @apply mb-0 rounded-b;
}

.list-group-item.disabled, .list-group-item:disabled {
    @apply text-gray-600 bg-white pointer-events-none
}

.list-group-item-action {
    @apply w-full text-gray-900;
}

.list-group-item-action:hover {
    @apply text-gray-900 bg-gray-100;
}

.list-group-item.active {
    @apply text-white bg-blue-600 z-10;
}


/*
* ============================
* Modals
*=============================
*/

.fade {
    transition: opacity .15s linear;
}

.modal {
    z-index: 1072;
    @apply hidden fixed top-0 left-0 w-full h-full outline-none
}

.modal.show, .modal.in {
    @apply block
}

.modal-dialog {
    @apply relative w-auto pointer-events-none max-w-lg my-8 mx-auto
}

.modal-content {
    @apply relative flex flex-col w-full pointer-events-auto bg-white border border-gray-300 rounded-lg
}

.modal-header {
    @apply flex items-start justify-between p-4
}

.modal-title {
    @apply mb-0 leading-normal
}

.modal-body {
    @apply relative flex p-4
}

.modal-footer {
    @apply flex items-center justify-end p-4 border-t border-gray-300
}

.modal-footer > :not(:last-child) {
    @apply mr-2
}

/*
* ============================
* Navbars
*=============================
*/

.navbar {
    @apply relative flex flex-wrap items-center justify-between py-1 px-4;
}

.fixed-top {
    @apply fixed top-0 right-0 left-0 z-40
}

.navbar-brand {
    @apply inline-block py-1 mr-4 text-xl
}

.navbar-light .navbar-brand {
    @apply text-gray-900
}

.navbar-dark .navbar-brand {
    @apply text-gray-100
}

.navbar-toggler {
    @apply inline-block py-1 px-3 leading-none bg-transparent border border-transparent rounded
}

.navbar-light .navbar-toggler {
    @apply text-gray-500 border-gray-400
}

.navbar-dark .navbar-toggler {
    @apply text-gray-500 border-gray-700
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-toggler-icon {
    content: "";
    @apply inline-block w-6 h-6 align-middle bg-center bg-no-repeat
}

.navbar-collapse {
    @apply hidden w-full flex-grow items-center
}

.navbar-collapse.show, .navbar-collapse.in {
    @apply block
}

.navbar-nav {
    @apply flex flex-col pl-0 mb-0 list-none;
}

.nav-link {
    @apply block py-2 px-4
}

.collapse.show .nav-link, .collapse.in .nav-link {
    @apply px-0
}

.navbar-light .navbar-nav .nav-link  {
    @apply text-gray-600
}

.navbar-light .navbar-nav .nav-link:hover {
    @apply text-gray-800
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active {
    @apply text-gray-900
}

.navbar-light .navbar-nav .nav-link.disabled {
    @apply text-gray-500 pointer-events-none
}

.navbar-dark .navbar-nav .nav-link  {
    @apply text-gray-600
}

.navbar-dark .navbar-nav .nav-link:hover {
    @apply text-gray-500
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
    @apply text-gray-200
}

.navbar-dark .navbar-nav .nav-link.disabled {
    @apply text-gray-700 pointer-events-none
}

/*
* ============================
* Tables
*=============================
*/

th {
    text-align: inherit;
}

.table {
    @apply w-full text-gray-900;
}

// .table thead th {
//     @apply align-bottom border-b-2 border-gray-300
// }

// .table td, .table th {
//     @apply p-3 border-t border-gray-300
// }

.table.table-bordered {
    @apply border border-gray-300
}

.table-bordered td, .table-bordered th {
    @apply border border-gray-300
}
